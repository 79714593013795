import React, { useEffect, useState } from "react";
import mirror_perf from "../data/mirror_performance_metrics.json";
import dinov2_perf from "../data/dinov2_performance_metrics.json";
import { Box, Stack, Typography } from "@mui/material";
import PerformanceTable from "../components/PerformanceTable";
import MuiMarkdown from "mui-markdown";
import perf_markdown from "./performance_summary.md";

function PerformancePage() {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    fetch(perf_markdown)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text));
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Stack
        sx={{
          borderRadius: 5,
          border: 5,
          borderColor: "lightgray",
          // fontSize: 20,
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Typography
          align="center"
          sx={{
            color: "secondary.main",
            borderBottom: 3,
            pt: 1,
            pb: 2,
            backgroundColor: "lightgray",
            borderColor: "secondary.main",
            // pl: 55,
            fontSize: 26,
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Simulation Technical Summary
        </Typography>
        <Box
          sx={{
            bgcolor: "background.main",
            p: 2,
            "& h1": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h2": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h3": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h4": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h5": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& h6": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
            },
            "& p": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 1, // Adds space below h4 headers
            },
            "& table": {
              marginBottom: 1, // Adds space below h4 headers
              marginTop: 2, // Adds space below h4 headers
              border: "1px solid #ddd", // Adds border to table cells
              width: "100%", // Makes table width 100%
              borderCollapse: "collapse", // Ensures borders are collapsed
            },
            "& th, & td": {
              border: "1px solid #ddd", // Adds border to table cells
              padding: "8px", // Adds padding to table cells
            },
            "& th": {
              backgroundColor: "lightgray", // Adds background color to table headers
            },
            "& img": {
              border: "1px solid #aaa", // Adds border to table cells
              display: "block", // Ensures the image is treated as a block element
              // margin: "16px auto", // Adds margin on top and bottom, centers the image horizontally
              marginTop: 2, // Adds padding above the image
              marginBottom: 2, // Adds padding below the image
            },
          }}
        >
          <MuiMarkdown>{markdownContent}</MuiMarkdown>
        </Box>
        <PerformanceTable perf_data={mirror_perf} model={"Mirror"}/> 
        <PerformanceTable perf_data={dinov2_perf} model={"DinoV2"}/> 
      </Stack>
    </Box>
  );
}

export default PerformancePage;
