import {
  Box,
  Button,
  Divider,
  IconButton,
  ImageList,
  Modal,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { blue, blueGrey, green, grey, purple, red } from "@mui/material/colors";
import React, { useState } from "react";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ClearIcon from "@mui/icons-material/Clear";

const outer_header_style = {
  fontSize: 12,
  color: grey[800],
  fontWeight: "bold",
};
const inner_row_style = {
  fontSize: 16,
  color: grey[800],
};

const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxHeight: "90%",
  maxWidth: "1600px",
  backgroundColor: "white",
  border: "1px solid #000",
  borderRadius: 5,
  boxShadow: 10,
  p: 3,
  mt: 2,
};

function PerformanceScenarioTable({ instance_metrics, model }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imgModalOpen, setImageModalOpen] = useState(false);
  const [currentInstanceInfo, setCurrentInstanceInfo] = useState({});
  const [currentScenarioInfo, setCurrentScenarioInfo] = useState({});

  const handleImageModelOpen = (imgs_data, instance_info) => {
    setSelectedImages(imgs_data.imgs);
    setCurrentScenarioInfo(imgs_data);
    setCurrentInstanceInfo(instance_info);
    setImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setImageModalOpen(false);
    setSelectedImages([]);
  };

  return (
    <Table
      size="small"
      aria-label="additional metrics"
      sx={{ border: 0, borderRadius: 3, bgcolor: blueGrey[100], }}
    >
      <TableHead sx={{ p: 0 }}>
        <TableRow
          dense
          sx={{ borderBottom: 2, borderColor: blueGrey[300], p: 0 }}
        >
          <TableCell align={"center"} style={outer_header_style}>
            Threads
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            Delay
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            Duration
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            Transactions/Sec
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            Latency (min {"\u2022"} max {"\u2022"} avg)
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            CPU Util
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            Memory Util
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            GPU Util
          </TableCell>
          <TableCell align={"center"} style={outer_header_style}>
            {" "}
            Charts
          </TableCell>

          {/* Add more columns as needed */}
        </TableRow>
      </TableHead>
      {instance_metrics.data.map((instance_config, index) => (
        <TableRow key={index}>
          <TableCell align={"center"} style={inner_row_style}>
            {instance_config.number_of_threads}
          </TableCell>
          <TableCell align={"center"} style={inner_row_style}>
            {instance_config.delay_in_seconds}s
          </TableCell>
          <TableCell align={"center"} style={inner_row_style}>
            {instance_config.duration_in_seconds}s
          </TableCell>
          <TableCell align={"center"} style={inner_row_style}>
            {instance_config.number_transactions_per_second}
          </TableCell>
          <TableCell align={"center"} style={inner_row_style}>
            {instance_config.latency.min} {"\u2022"}{" "}
            {instance_config.latency.max} {"\u2022"}{" "}
            {instance_config.latency.avg}
          </TableCell>
          <TableCell
            align={"center"}
            style={inner_row_style}
            sx={{ fontWeight: "bold" }}
          >
            {instance_config.cpu_util === -1 ? (
              <Typography sx={{ color: blueGrey[200] }}>X</Typography>
            ) : (
              instance_config.cpu_util
            )}
          </TableCell>
          <TableCell
            align={"center"}
            style={inner_row_style}
            sx={{ fontWeight: "bold" }}
          >
            {instance_config.memory_util === -1 ? (
              <Typography sx={{ color: blueGrey[200] }}>X</Typography>
            ) : (
              instance_config.memory_util
            )}
          </TableCell>
          <TableCell
            align={"center"}
            style={inner_row_style}
            sx={{ fontWeight: "bold" }}
          >
            {instance_config.gpu_util === -1 ? (
              <Typography sx={{ color: blueGrey[200] }}>X</Typography>
            ) : (
              instance_config.gpu_util
            )}
          </TableCell>
          <TableCell
            align={"center"}
            style={inner_row_style}
            sx={{ fontWeight: "bold" }}
          >
            {instance_config.imgs.length > 0 ? (
              <IconButton
                onClick={() =>
                  handleImageModelOpen(instance_config, instance_metrics)
                }
                sx={{
                  border: 1,
                  borderColor: blueGrey[200],
                  alignSelf: "center",
                }}
              >
                <QueryStatsIcon sx={{ width: 15, height: 15 }} />
              </IconButton>
            ) : (
              <Typography sx={{ color: blueGrey[200] }}>X</Typography>
            )}
          </TableCell>
        </TableRow>
      ))}
      <Modal
        open={imgModalOpen}
        onClose={handleCloseImageModal}
        aria-labelledby="enlarged-image-modal-table"
      >
        <Box style={imageModalStyle} sx={{ p: 2 }}>
          <Stack spacing={2} direction={"row"} sx={{ alignItems: "center" }}>
            <Button
              variant="contained"
              onClick={handleCloseImageModal}
              sx={{
                borderRadius: 3,
                textTransform: "unset",
                backgroundColor: "primary.main",
              }}
              startIcon={<ClearIcon />}
            >
              CLOSE
            </Button>
            <Stack>
              <Typography sx={{ fontWeight: "bold" }}>
                {currentInstanceInfo.instance_type}{" "}
              </Typography>
              <Typography>{currentInstanceInfo.instance_tier} </Typography>
            </Stack>

            <Typography>vCPUs: {currentInstanceInfo.vCPU_count} </Typography>
            <Typography>vGPUs: {currentInstanceInfo.vGPU_count} </Typography>
            <Typography>
              Memory: {currentInstanceInfo.memory_GiB}GiB{" "}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{ height: 20, backgroundColor: "black" }}
            />
            <Typography>CPU Util: {currentScenarioInfo.cpu_util} </Typography>
            <Typography>
              Memory Util: {currentScenarioInfo.memory_util}{" "}
            </Typography>
            {currentScenarioInfo.gpu_util === -1 ? null : (
              <Typography>GPU Util: {currentScenarioInfo.gpu_util} </Typography>
            )}
          </Stack>

          <ImageList cols={2} sx={{ overflow: "auto" }}>
            {selectedImages.map((current_image, index) => (
              <Box
                component="img"
                sx={{
                  maxWidth: "100%",
                  maxHeight: 700,
                  objectFit: "contain",
                  cursor: "pointer",
                  border: 2,
                }}
                alt={current_image.alt}
                src={`${process.env.PUBLIC_URL}/images/${model}_performance/${instance_metrics.instance_type}/${current_image.src}`}
              />
            ))}
          </ImageList>
        </Box>
      </Modal>
    </Table>
  );
}

export default PerformanceScenarioTable;
