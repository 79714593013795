import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import PerformanceScenarioTable from "./PerformanceScenarioTable";

const outer_header_style = {
  fontSize: 16,
  color: grey[900],
  fontWeight: "bold",
};

function PerformanceTable({ perf_data, model }) {
  const instanceRowNumCols = 6;

  return (
    <TableContainer sx={{ p: 2 }}>
      <Table size="small" sx={{ border: 2, borderRadius: 3 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "secondary.main" }}>
            <TableCell colSpan={instanceRowNumCols} sx={{ color: "white" }}>
              {model}
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: "lightgray" }}>
            <TableCell align="left" style={outer_header_style}>
              Instance Type
            </TableCell>
            <TableCell align="center" style={outer_header_style}>
              vCPUs
            </TableCell>
            <TableCell align="center" style={outer_header_style}>
              vGPUs
            </TableCell>
            <TableCell align="center" style={outer_header_style}>
              Memory
            </TableCell>
            <TableCell align="center" style={outer_header_style}>
              Cost/Hour
            </TableCell>
            <TableCell align="center" style={outer_header_style}>
              Scenarios
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {perf_data.map((instance_metrics, index) => (
            <InstanceRow
              key={index}
              instance_metrics={instance_metrics}
              instanceRowNumCols={instanceRowNumCols}
              model={model.toLowerCase()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function InstanceRow({ instance_metrics, instanceRowNumCols, model }) {
  return (
    <TableRow sx={{ border: 0, borderColor: "secondary.main" }}>
      <TableCell sx={{ border: 0, border:0, verticalAlign: 'top', pt:1  }}>
        <Stack>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            {instance_metrics.instance_type}
          </Typography>
          <Typography sx={{ color: grey[700] }}>
            {instance_metrics.instance_tier}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="center" sx={{ border: 0,verticalAlign: 'top', pt:2    }}>
        {instance_metrics.vCPU_count}
      </TableCell>
      <TableCell align="center" sx={{ border: 0,verticalAlign: 'top', pt:2   }}>
        {instance_metrics.vGPU_count}
      </TableCell>
      <TableCell align="center" sx={{ border: 0,verticalAlign: 'top', pt:2   }}>
        {instance_metrics.memory_GiB}GiB
      </TableCell>
      <TableCell align="center" sx={{ border: 0, verticalAlign: 'top', pt:2   }}>
        {instance_metrics.cost_per_hour}
      </TableCell>
      <TableCell align="center" sx={{ border: 0,verticalAlign: 'top' }}>
        <PerformanceScenarioTable
          instance_metrics={instance_metrics}
          model={model}
        />
      </TableCell>
    </TableRow>
  );
}

export default PerformanceTable;
