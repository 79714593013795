import React, { useContext, useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ModelContext } from "../ModelContext";
import { useLocation } from "react-router-dom";

function ModelTypeToggle() {
  const { modelSelected, setModelSelected } = useContext(ModelContext);
  const location = useLocation();
  const disabled_paths = ["/about", "/performance"];
  const [isDisabled, setIsDisabled] = useState(
    disabled_paths.includes(location.pathname)
  );

  useEffect(() => {
    setIsDisabled(disabled_paths.includes(location.pathname));
  }, [location.pathname]);
  
  if (isDisabled) {
    return null;
  }
  return (
    <Box>
      <ButtonGroup disabled={isDisabled} sx={{ px: 4 }}>
        <Button
          startIcon={modelSelected === "dinov2" ? <PlayArrowIcon /> : null}
          onClick={() => setModelSelected("dinov2")}
          sx={{
            width: 100,
            textTransform: "none",
            borderRadius: 3,

            borderColor: "white",
            fontSize: 16,
            fontWeight: modelSelected === "dinov2" ? "bold" : "light",
            backgroundColor:
              modelSelected === "dinov2" ? "white" : "secondary.main",
            color: modelSelected === "dinov2" ? "secondary.main" : "white",
            "&:hover": {
              backgroundColor: "gray",
              borderColor: "gray",
              color: "white",
              // color: isActive ? "white" : "secondary.main",
            },
          }}
        >
          DinoV2
        </Button>
        <Button
          startIcon={modelSelected === "titan" ? <PlayArrowIcon /> : null}
          onClick={() => setModelSelected("titan")}
          sx={{
            width: 100,
            textTransform: "none",
            borderRadius: 3,

            borderColor: "white",
            fontSize: 16,
            fontWeight: modelSelected === "titan" ? "bold" : "light",
            backgroundColor:
              modelSelected === "titan" ? "white" : "secondary.main",
            color: modelSelected === "titan" ? "secondary.main" : "white",
            "&:hover": {
              backgroundColor: "gray",
              borderColor: "gray",
              color: "white",
              // color: isActive ? "white" : "secondary.main",
            },
          }}
        >
          Titan
        </Button>
      </ButtonGroup>
    </Box>
  );
}

export default ModelTypeToggle;
